import React, { useState, useEffect } from "react";
import "../styles/promoBubble.css"; 
const PromoBubble = () => {
  const bubbles = [
    {
      title: "🏡 Wynajem domku w Eko Zakątku – idealny wypoczynek w górach",
      content: (
        <>
          Zapraszamy do komfortowego domku w górach, idealnego dla 3-5 osób.  
          Ceny dostosowane do długości pobytu oraz liczby osób:
          <ul>
            <li><b>Weekend i święta:</b> 500 zł za domek dla 3-5 osób, 400 zł dla 2 osób</li>
            <li><b>Pozostałe dni:</b> 450 zł za domek dla 3-5 osób, 350 zł dla 2 osób</li>
            <li><b>Dodatkowa osoba:</b> 80 zł za dobę</li>
          </ul>
        </>
      ),
    },
    {
      title: "🌿 Strefa SPA – Relaks na wyciągnięcie ręki",
      content: (
        <>
          Zadbaj o swoje ciało i umysł, korzystając z naszej strefy <b>SPA:</b>
          <ul>
            <li><b>Sauna:</b> 80 zł/godzina</li>
            <li><b>Jacuzzi:</b> 2 godziny – 180 zł, każda kolejna godzina – 40 zł</li>
            <li>
              Opcja <b>całodziennego</b> jacuzzi z samodzielnym rozgrzewaniem (do godz. 22): 300 zł
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "🐾 Pobyt ze zwierzętami – Ekozacisze dla całej rodziny",
      content: (
        <>
          Akceptujemy do dwóch piesków lub kotków w domku!<br></br>  
          <b>Opłata:</b> 25 zł/doba za zwierzaka. 
          <b> Minimalna rezerwacja:</b> 2 doby.
        </>
      ),
    },
    {
      title: "🏔 Ferie w Eko Zakątku w górach! 2 2300 zł",
      content: (
        <>
          <b>Zarezerwuj pobyt na 5 dni</b> i zyskaj wyjątkowy dzień relaksu w jacuzzi oraz 2 sesje sauny po godzinie w cenie!
          Odetchnij świeżym, górskim powietrzem, <b>zregeneruj ciało i zrelaksuj się w otoczeniu natury.</b> 
          <br></br>Idealny wypoczynek dla ciebie i twoich bliskich – komfort, spokój i niezapomniane zimowe widoki!
         <br></br><br></br> <b>Rezerwuj już teraz i spędź ferie w harmonii z naturą!</b>
        </>
      ),
    },
  ];

  const [currentBubble, setCurrentBubble] = useState(0); // Indeks aktualnego dymka
  const [isVisible, setIsVisible] = useState(true); // Widoczność dymka

  // Funkcja zmieniająca dymek co 10 sekund
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBubble((prev) => (prev + 1) % bubbles.length); // Przechodzi do następnego dymka
      setIsVisible(true); // Przy każdym przełączeniu ponownie pokazujemy dymek
    }, 15000); // Co 30 sekund

    return () => clearInterval(interval);
  }, [bubbles.length]);

  // Funkcja do zamykania dymka
  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="promo-bubble">
        <div className="promo-bubble-content">
          <h3>{bubbles[currentBubble].title}</h3>
          <p>{bubbles[currentBubble].content}</p>
        </div>
        <button className="promo-bubble-close" onClick={handleClose}>
          ✖
        </button>
      </div>
    )
  );
};

export default PromoBubble;